import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const {
  APP_VERSION,
  APP_ENV,
  API_URL,
  API_URL_MEANDU,
  MANAGE_URL,
  CLIENT_URQL_TIMEOUT,
  REGION,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_REDIRECT_URI,
} = publicRuntimeConfig

export const appVersion = APP_VERSION
const region = REGION || 'ap1'

const apiUrl = API_URL
const apiUrlMeandu = API_URL_MEANDU
const auth0Audience = AUTH0_AUDIENCE
const auth0ClientId = AUTH0_CLIENT_ID
const auth0Domain = AUTH0_DOMAIN
const auth0RedirectUri = AUTH0_REDIRECT_URI

const manageUrl = MANAGE_URL || 'https://manage.mryum.local'
const clientUrqlTimeoutMilliseconds = CLIENT_URQL_TIMEOUT || 90000
const urqlTimeoutMilliseconds = clientUrqlTimeoutMilliseconds
const clientVersionFetchIntervalMinutes = 60

type Auth0Config = {
  auth0Audience: string
  auth0Domain: string
  auth0ClientId: string
  auth0RedirectUri: string
}

interface ConfigValue extends Auth0Config {
  environment: string
  /** Default region */
  region: string
  amplitudeKey: string
  apiUrl: string
  apiUrlMeandu: string
  lokeClientId: string
  lokeAuthUrl: string
  lokeCallbackUrl: string
  kountaClientId: string
  kountaClientIdEu: string
  kountaAuthUrl: string
  kountaCallbackUrl: string
  squareClientId: string
  squareClientIdEu: string
  squareClientIdUs: string
  squareAuthUrl: string
  squareCallbackUrl: string
  squareScope: string
  gaTrackingID: string
  bugsnagKey: string
  googlePlacesApiKey: string
  frontendURL: string
  hyperwalletServer: string
  beamerApiKey: string
  googleTagId: string
  // @deprecated: moving to `googleTagId`
  googleTagManagerId: string
  stripeAUD: string
  stripeNZD: string
  stripeUSD: string
  stripeSGD: string
  stripeGBP: string
  stripeEUR: string
  urqlTimeoutMilliseconds: number
  auth0UsersDashboardUrl: string
  clientVersionFetchIntervalMinutes: number
}

interface ConfigData {
  staging: ConfigValue
  test: ConfigValue
  development: ConfigValue
  production: ConfigValue
}

const stripeUKTest =
  'pk_test_51HlWdID0luSv8eqWyu6PAk0RiZ3X8omrb07R2kPyCmYNSDxBmi1IClnQsB8FUupa4hZ3ocHlzGBXbS4C8QQoeCPC00NRane1eD'

const stripeTestKeys = {
  stripeAUD: 'pk_test_gtRdjjtoOFsZqEvtkSD4sVir',
  stripeNZD: 'pk_test_jiNkiilHj7n4JY52tCJOu3fo00ZUnEYwWi',
  stripeSGD:
    'pk_test_51JyKqHBshqr84imdjweJ1tFyfDo0av90aoYFX4L4D3EB7RF60Az5lDVeDGeoynCCxJhicudYNlBrLEYN2thsZ1mu00LprRwf1s',
  stripeUSD:
    'pk_test_51Hbe2QEvPYHKZhcgO40TnOTETuPgRlYgqb26TZsn4Rlz2Qjv25rTYDRO6303qlzE6LaBu6QBfy4VXWWs7lU4bLG900K6ZDig1s',
  stripeGBP: stripeUKTest,
  stripeEUR: stripeUKTest,
}
const stripeUKLive =
  'pk_live_51HlWdID0luSv8eqWOdcn3Sa3lbvymorvXqgkma1CohZF3hfDPu5OsaU2ivGfTLWAesYyE7muXyOlbwEtR5OAio8g003mey2VY5'

const stripeLiveKeys = {
  stripeAUD: 'pk_live_ifyBluZAKnJmYbduIOVv512K',
  stripeNZD: 'pk_live_cx5rxJ1Hm0DFjta5u6VC5aGl00NCZaDy4L',
  stripeUSD:
    'pk_live_51Hbe2QEvPYHKZhcgWHpYhdjczuPzSxTeIW0piCHrQCDaDTALJy3lcobvLpewB8HXbAJx7FOpOCNjiQUnQxCuATRL00NH0KlZcE',
  stripeSGD:
    'pk_live_51JyKqHBshqr84imdw6NMA3QmHa2J8f2N3B3lk8TG3JHLyWQEMlFcjPQixghOZtQpmmOPUddglwaInG0mq96yJLwV00NIJcKSwm',
  stripeGBP: stripeUKLive,
  stripeEUR: stripeUKLive,
}

const auth0Config: Auth0Config = {
  auth0Audience,
  auth0ClientId,
  auth0Domain,
  auth0RedirectUri,
}

const configData: ConfigData = {
  staging: {
    environment: 'staging',
    region,
    amplitudeKey: 'f5ee113af9f632c47123eae3c4f45dd3',
    apiUrl,
    apiUrlMeandu,
    lokeClientId: 'aafe9a5e9e76ca3aa4d7',
    lokeAuthUrl: 'https://auth.sbox.loke.global/authorize/organization',
    lokeCallbackUrl: 'https://staging-api.mryum.com/oauth/loke/callback',
    kountaClientId: 'WrsO7j7CZf8x4fvi',
    kountaClientIdEu: 'plQHCccoLLY3c9Uy',
    kountaAuthUrl: 'https://my.kounta.com/authorize',
    kountaCallbackUrl: `${manageUrl}/oauth/kounta`,
    squareClientId: 'sandbox-sq0idb-2-TAdhFpFe0GiWrbUR5MAQ',
    squareClientIdEu: 'sandbox-sq0idb-hDpvZlCqmFYcr8nRhTbgFA',
    squareClientIdUs: 'sandbox-sq0idb-jPFQgL4dpS1-0sOC6JzY2g',
    squareAuthUrl: 'https://connect.squareupsandbox.com/oauth2/authorize',
    squareCallbackUrl: `${manageUrl}/oauth/square`,
    squareScope:
      'ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ+INVENTORY_READ+ITEMS_READ+PAYMENTS_READ+PAYMENTS_WRITE',
    gaTrackingID: 'UA-129043240-3',
    bugsnagKey: '40e0596b96505cc1eacf800527209fee',
    googlePlacesApiKey: 'AIzaSyA8kTkrv_P9n9dcvkPQsHtAgn896ZllPJI',
    frontendURL: 'https://staging.mryum.com',
    hyperwalletServer: 'https://uat.hyperwallet.com',
    beamerApiKey: 'b_LnD8wxqDuqbAMQoSjJ5b/uLpyLmKdevKnwbwy9PWOJU=',
    googleTagId: 'G-DW6MH4K1EX',
    googleTagManagerId: 'GTM-NNPBGC8',
    ...stripeTestKeys,
    urqlTimeoutMilliseconds,
    // for if we ever bring back eu1/us1 staging
    auth0Audience: auth0Audience || 'https://staging-api.mryum.com/',
    auth0ClientId: auth0ClientId || 'OGw8Ss6jR39TJ5DmMDa0zyEkxhs1SxIR',
    auth0Domain: auth0Domain || 'staging-auth.mryum.com',
    auth0RedirectUri: auth0RedirectUri || 'https://manage.mryum.local',
    auth0UsersDashboardUrl:
      'https://manage.auth0.com/dashboard/au/mr-yum-staging/users',
    clientVersionFetchIntervalMinutes,
  },
  production: {
    environment: 'production',
    region,
    apiUrl,
    apiUrlMeandu,
    amplitudeKey: 'f5ee113af9f632c47123eae3c4f45dd3',
    gaTrackingID: 'UA-129043240-1',
    lokeClientId: 'fe8ac94bf95bfed70a3d',
    lokeAuthUrl: 'https://auth.au.loke.global/authorize/organization',
    lokeCallbackUrl: 'https://api.mryum.com/oauth/loke/callback',
    kountaClientId: 'FbaDRObTK9RpWWf3',
    kountaClientIdEu: 'bqlbMGaiIqtVW9ST',
    kountaAuthUrl: 'https://my.kounta.com/authorize',
    kountaCallbackUrl: `${manageUrl}/oauth/kounta`,
    squareClientId: 'sq0idp-wMMSD0XQFdMyLZ738WMiBg',
    squareClientIdEu: 'sq0idp-zv6M9ySh_QWF3k3jmP8lNA',
    squareClientIdUs: 'sq0idp-gtjZhvUOFFln4Kom3diS-A',
    squareAuthUrl: 'https://connect.squareup.com/oauth2/authorize',
    squareCallbackUrl: `${manageUrl}/oauth/square`,
    squareScope:
      'ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ+INVENTORY_READ+ITEMS_READ+PAYMENTS_READ+PAYMENTS_WRITE',
    bugsnagKey: '40e0596b96505cc1eacf800527209fee',
    googlePlacesApiKey: 'AIzaSyA8kTkrv_P9n9dcvkPQsHtAgn896ZllPJI',
    frontendURL: 'https://www.mryum.com',
    hyperwalletServer: 'https://pay.hyperwallet.com',
    beamerApiKey: 'b_LnD8wxqDuqbAMQoSjJ5b/uLpyLmKdevKnwbwy9PWOJU=',
    googleTagId: 'G-H8DDZBM58S',
    googleTagManagerId: 'GTM-K68D9FS',
    ...stripeLiveKeys,
    urqlTimeoutMilliseconds,
    ...auth0Config,
    auth0UsersDashboardUrl:
      'https://manage.auth0.com/dashboard/au/mr-yum-production/users',
    clientVersionFetchIntervalMinutes,
  },
  test: {
    environment: 'test',
    region,
    amplitudeKey: 'f5ee113af9f632c47123eae3c4f45dd3',
    apiUrl: 'https://api.mryum.local',
    apiUrlMeandu: '',
    gaTrackingID: 'UA-129043240-3',
    lokeClientId: 'aafe9a5e9e76ca3aa4d7',
    lokeAuthUrl: 'https://auth.sbox.loke.global/authorize/organization',
    lokeCallbackUrl: 'https://api.mryum.local/oauth/loke/callback',
    kountaClientId: '7EmY9cE7UoKZfi2P',
    kountaClientIdEu: '7EmY9cE7UoKZfi2P',
    kountaAuthUrl: 'https://my.kounta.com/authorize',
    kountaCallbackUrl: 'https://6a16cb6b.au.ngrok.io/oauth/kounta',
    squareClientId: 'sandbox-sq0idb-2-TAdhFpFe0GiWrbUR5MAQ',
    squareClientIdEu: 'sandbox-sq0idb-hDpvZlCqmFYcr8nRhTbgFA',
    squareClientIdUs: 'sandbox-sq0idb-jPFQgL4dpS1-0sOC6JzY2g',
    squareAuthUrl: 'https://connect.squareupsandbox.com/oauth2/authorize',
    squareCallbackUrl: `${manageUrl}/oauth/square`,
    squareScope:
      'ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ+INVENTORY_READ+ITEMS_READ+PAYMENTS_READ+PAYMENTS_WRITE',
    bugsnagKey: '00000000000000000000000000000000',
    googlePlacesApiKey: 'AIzaSyA8kTkrv_P9n9dcvkPQsHtAgn896ZllPJI',
    frontendURL: 'https://mryum.local',
    hyperwalletServer: 'https://uat.hyperwallet.com',
    beamerApiKey: 'b_LnD8wxqDuqbAMQoSjJ5b/uLpyLmKdevKnwbwy9PWOJU=',
    googleTagId: 'G-DW6MH4K1EX',
    googleTagManagerId: 'GTM-NNPBGC8',
    ...stripeTestKeys,
    urqlTimeoutMilliseconds,
    auth0Audience: 'https://manage.mryum.local/api/dev/ap1/graphql',
    auth0ClientId: 'gjD06F65ssw0uSUQZeuHkb0L7K3XeMvo',
    auth0Domain: 'mr-yum-development.au.auth0.com',
    auth0RedirectUri: 'https://manage.mryum.local/',
    auth0UsersDashboardUrl:
      'https://manage.auth0.com/dashboard/au/mr-yum-staging/users',
    clientVersionFetchIntervalMinutes,
  },
  development: {
    environment: 'development',
    region,
    amplitudeKey: 'f5ee113af9f632c47123eae3c4f45dd3',
    apiUrl: 'https://manage.mryum.local/api/dev/ap1',
    apiUrlMeandu: '',
    gaTrackingID: 'UA-129043240-3',
    lokeClientId: 'aafe9a5e9e76ca3aa4d7',
    lokeAuthUrl: 'https://auth.sbox.loke.global/authorize/organization',
    lokeCallbackUrl: 'https://mryum.au.ngrok.io/oauth/loke/callback',
    kountaClientId: 'q2oNNNNSdkPT6jeU',
    kountaClientIdEu: '7EmY9cE7UoKZfi2P',
    kountaAuthUrl: 'https://my.kounta.com/authorize',
    kountaCallbackUrl: 'https://manage.mryum.local/oauth/kounta',
    squareClientId: 'sandbox-sq0idb-2-TAdhFpFe0GiWrbUR5MAQ',
    squareClientIdEu: 'sandbox-sq0idb-hDpvZlCqmFYcr8nRhTbgFA',
    squareClientIdUs: 'sandbox-sq0idb-jPFQgL4dpS1-0sOC6JzY2g',
    squareAuthUrl: 'https://connect.squareupsandbox.com/oauth2/authorize',
    squareCallbackUrl: `${manageUrl}/oauth/square`,
    squareScope:
      'ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ+INVENTORY_READ+ITEMS_READ+PAYMENTS_READ+PAYMENTS_WRITE',
    bugsnagKey: '00000000000000000000000000000000',
    googlePlacesApiKey: 'AIzaSyA8kTkrv_P9n9dcvkPQsHtAgn896ZllPJI',
    frontendURL: 'https://mryum.local',
    hyperwalletServer: 'https://uat.hyperwallet.com',
    beamerApiKey: 'b_LnD8wxqDuqbAMQoSjJ5b/uLpyLmKdevKnwbwy9PWOJU=',
    googleTagId: 'G-DW6MH4K1EX',
    googleTagManagerId: 'GTM-NNPBGC8',
    ...stripeTestKeys,
    urqlTimeoutMilliseconds,
    auth0Audience: 'https://manage.mryum.local/api/dev/ap1/graphql',
    auth0ClientId: 'gjD06F65ssw0uSUQZeuHkb0L7K3XeMvo',
    auth0Domain: 'mr-yum-development.au.auth0.com',
    auth0RedirectUri: 'https://manage.mryum.local/',
    auth0UsersDashboardUrl:
      'https://manage.auth0.com/dashboard/au/mr-yum-staging/users',
    clientVersionFetchIntervalMinutes,
  },
}

export type ValidConfigKeys = keyof ConfigData

export const releaseStage: ValidConfigKeys =
  (APP_ENV as ValidConfigKeys) || 'development'

export const config = configData[releaseStage]
