import amplitude from 'amplitude-js'
import { useAuth } from 'hooks/useAuth'
import { config } from 'lib/config'
import { useVenueInfoForTrackingQuery } from 'lib/gql'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect } from 'react'
import { isClient } from 'utils/env'

export const AmplitudeProvider = ({ children }: { children: ReactNode }) => {
  const amplitudeInstance = isClient && amplitude.getInstance()
  const { user } = useAuth()
  useEffect(() => {
    if (amplitudeInstance) {
      amplitudeInstance.init(config.amplitudeKey)
    }
  }, [amplitudeInstance])

  useEffect(() => {
    if (amplitudeInstance) {
      amplitudeInstance.setUserId(user.id)
    }
  }, [amplitudeInstance, user.id])

  return <>{children}</>
}

export const useAmplitude = () => {
  const { query } = useRouter()
  const venueId = Array.isArray(query.venueId)
    ? query.venueId[0]
    : query.venueId
  const [{ data }] = useVenueInfoForTrackingQuery({
    variables: { venueId: venueId! },
    pause: !venueId,
    requestPolicy: 'cache-first',
  })
  const { user } = useAuth()

  return {
    logEvent: (
      eventName: string,
      eventProperties?: Record<string, unknown>,
    ) => {
      isClient &&
        amplitude.getInstance().logEvent(eventName, {
          ...eventProperties,
          // Using venueId from the url instead of the graph query
          // As Venue type is used in several places and having the id on the graph query
          // Can cause an issue with cache invalidation and infinite request loops
          venueId,
          staffId: user.id,
          venueSlug: data?.adminVenue?.slug,
          organization: data?.adminVenue?.organization?.id,
          admin_area1: data?.adminVenue?.location?.adminArea1,
          venueType: data?.adminVenue?.venueTypes.join(','),
          orderingType: data?.adminVenue?.orderingType?.join(','),
          country: data?.adminVenue?.country,
          role: user.role,
        })
    },
  }
}
