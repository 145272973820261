import { config } from 'lib/config'

const appTitle = 'Manage'

export const getMetaTitle = (pageTitle?: string) => {
  if (config.environment !== 'production') {
    if (pageTitle) {
      return `${config.environment} - ${appTitle} - ${pageTitle}`
    }
    return `${config.environment} - ${appTitle}`
  }

  if (pageTitle) {
    return `${appTitle} - ${pageTitle}`
  }

  return appTitle
}
