import { AuthContextController } from 'lib/common-auth/context'
import { useContext } from 'react'

export const useAuth = () => {
  const value = useContext(AuthContextController)

  if (!value) {
    throw new Error('Something went wrong')
  }

  return value
}
