import { RedirectLoginOptions } from '@auth0/auth0-react'
import { SessionUser, State, ValidRole } from 'lib/auth'
import { StaffRole } from 'lib/gql'
import { createContext } from 'react'

export interface AuthContext {
  user: SessionUser
  userHasRole: (roles: ValidRole[] | undefined) => boolean
  isAdminOrMenuBuilder: () => boolean
  isAdmin: () => boolean
  state: State
  logout: () => void
  initLogout: () => void
  login: (options?: RedirectLoginOptions<any>) => void
  getToken: () => Promise<string>
  swapAccount: () => void
  initLogin: () => void
  refreshAuth: () => Promise<string | null>
  userHasStaffRole: (roles: readonly StaffRole[]) => boolean
}

export const AuthContextController = createContext<AuthContext | null>(null)

AuthContextController.displayName = 'AuthContext'
