import { CookieSerializeOptions } from 'cookie'
import { NextPageContext } from 'next'
import nookies from 'nookies'

export interface CookieAccess {
  all(): { [key: string]: string }
  get(name: string): string | undefined
  set(name: string, value: string, options?: CookieSerializeOptions): void
  destroy(name: string, options?: CookieSerializeOptions): void
}

/** Provides access to cookies where possible
 *
 * When request is present, cookies will be read from headers
 * When response is present, cookies will be set on headers
 * (If only one is present then the other methods will be no-ops)
 * When neither present then document.cookies will be used
 *
 * Internally handled by nookies
 */
export function cookiesForContext(
  ctx?: Pick<NextPageContext, 'req' | 'res'>,
): CookieAccess {
  return {
    all() {
      return nookies.get(ctx)
    },
    get(name: string): string | undefined {
      const all = nookies.get(ctx)
      return all[name]
    },
    set(name: string, value: string, options: CookieSerializeOptions = {}) {
      return nookies.set(ctx, name, value, options)
    },
    destroy(name: string, options: CookieSerializeOptions = {}) {
      return nookies.destroy(ctx, name, options)
    },
  }
}
