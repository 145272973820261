import { useScript } from '@mr-yum/frontend-core/dist/hooks/useScript'
import { ZENDESK_AUTH_FLAG } from 'common/constants'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { useZendeskAuthQuery } from 'lib/gql'
import { loginUser, ZENDESK_SNIPPET_ID, ZENDESK_SNIPPET_URI } from 'lib/zendesk'
import { useEffect, useState } from 'react'

export const useChatSupport = () => {
  /**
   * TODO(SRV-2393): Clean up feature flag
   */
  const { value: useZendeskAuth, ready: flagReady } =
    useFeatureFlag(ZENDESK_AUTH_FLAG)
  const [scriptLoaded, scriptLoadingError] = useScript(ZENDESK_SNIPPET_URI, {
    id: ZENDESK_SNIPPET_ID,
  })
  const [chatReady, setChatReady] = useState(false)
  const [{ data: zendeskAuthData }] = useZendeskAuthQuery({
    pause: !(flagReady && useZendeskAuth),
  })

  useEffect(() => {
    const scriptReady = scriptLoaded && !scriptLoadingError
    const featureEnabled = flagReady && useZendeskAuth
    const shouldLoginUser = !chatReady
    const token = zendeskAuthData?.zendeskAuth?.token

    if (featureEnabled && scriptReady && shouldLoginUser && token) {
      void (async () => {
        await loginUser(token)
        setChatReady(true)
      })()
      return
    }

    if (scriptLoadingError) {
      // eslint-disable-next-line no-console
      console.error('Zendesk chat script failed to load')
      return
    }
  }, [
    scriptLoaded,
    scriptLoadingError,
    chatReady,
    flagReady,
    useZendeskAuth,
    zendeskAuthData,
  ])

  return chatReady
}
