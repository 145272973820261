export const ZENDESK_SNIPPET_URI =
  '//static.zdassets.com/ekr/snippet.js?key=0b59f9e6-9209-4abb-bef0-2bcf6026c4d9'
export const ZENDESK_SNIPPET_ID = 'ze-snippet'

type ZendeskCallback = (jwt: string) => void

declare global {
  interface Window {
    zE: (
      command: string,
      action: string,
      callbackHandler?: (callback: ZendeskCallback) => void,
    ) => void
  }
}

/**
 * Promisified version of the Zendesk auth login call.
 *
 * Source: https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/#login
 */
export const loginUser = async (token: string): Promise<void> => {
  return new Promise((resolve) => {
    if (!window.zE) {
      return
    }

    window.zE('messenger', 'loginUser', (callback: ZendeskCallback) => {
      callback(token)
      resolve()
    })
  })
}

/**
 * Source: https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/#logout
 */
export const logoutUser = (): void => {
  window.zE('messenger', 'logoutUser')
}
