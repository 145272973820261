import { DEFAULT_REGION, Region } from 'lib/region'
import React, { createContext, useContext, useEffect, useState } from 'react'

interface Props {
  region?: Region
}

interface RegionContextType {
  region: Region
  /** Doesn't yet persist region */
  setRegion(region: Region): void
}

export const RegionContext = createContext<RegionContextType>(
  {} as RegionContextType,
)

export const useRegion = () => useContext(RegionContext)

export const RegionProvider = ({
  region: propsRegion,
  children,
}: React.PropsWithChildren<Props>) => {
  const [region, setRegion] = useState<Region>(propsRegion ?? DEFAULT_REGION)
  const value: RegionContextType = {
    region,
    setRegion,
  }

  useEffect(() => setRegion(propsRegion ?? DEFAULT_REGION), [propsRegion])

  return (
    <RegionContext.Provider value={value}>{children}</RegionContext.Provider>
  )
}
