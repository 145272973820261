import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { Alert, Button, ErrorNotification } from '@mr-yum/frontend-ui'
import { routes } from 'lib/routes'
import React, { ReactNode } from 'react'

interface NotFoundProps {
  title?: ReactNode
  message?: ReactNode
  error?: Error | null
  clearError?: () => void
}

export const NotFound = ({
  title,
  message,
  error,
  clearError,
}: NotFoundProps) => (
  <div className="flex h-dvh w-dvw flex-col items-center justify-center bg-warning-50 p-4 text-center">
    {/* eslint-disable-next-line @next/next/no-img-element */}
    <img src="/static/mryum_assets/toast.png" alt="burnt toast" width={100} />
    <h4 className="mb-8 mt-4 my-heading-sm">
      {title || `Bummer, we got nothin'. Please check the URL for typos.`}
    </h4>
    <div className="flex flex-row gap-2">
      <RouteLink route={routes.home}>
        <Button size="lg">Go back</Button>
      </RouteLink>
      {clearError && (
        <Button size="lg" variant="outline" onClick={clearError}>
          Try again
        </Button>
      )}
    </div>
    {message && (
      <div className="mt-14 p-8">
        <Alert isCloseable variant="neutral-subtle">
          {message}
        </Alert>
      </div>
    )}
    {error && (
      <div className="mt-14 p-8">
        <ErrorNotification error={error} />
      </div>
    )}
  </div>
)
