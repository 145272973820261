import Bugsnag from '@bugsnag/js'
import BugsnagReact from '@bugsnag/plugin-react'
import React from 'react'

import { config, releaseStage } from './config'

if (process.env.NODE_ENV !== 'test') {
  Bugsnag.start({
    apiKey: config.bugsnagKey,
    releaseStage,
    appVersion: process.env.APP_VERSION,
    plugins: [new BugsnagReact(React)],
  })
}

export { Bugsnag }

export const reportError = (error: Error) => {
  Bugsnag.notify(error)
}

export const reportErrorContext = (error: Error, context: string) => {
  Bugsnag.notify(error, (event) => {
    ;(event as any).context = context
  })
}
