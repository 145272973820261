import { ManageRoute, MatchRoute, RouteName, routes } from 'lib/routes'
import { mapValues } from 'lodash'
import kebabCase from 'lodash/kebabCase'
import { ParsedUrlQuery } from 'querystring'

const IdReg = /Id$/i

/** Finds route which handles a given URL
 *
 * Ignores query params and hash
 */
// @enhance: Ideally this function shouldn't need to know the query object
// (route params) to work but, great news!, we can fix that if each route
// specifies a path to use in `path-to-regex`'s
// [Match](https://github.com/pillarjs/path-to-regexp#match) function
const matchRoute = (
  url: string,
  query: ParsedUrlQuery,
): [ManageRoute<{} | undefined>, Record<string, any>] | undefined => {
  for (const routeName in routes) {
    const config = routes[routeName as RouteName] as ManageRoute<
      {} | undefined
    > &
      Partial<MatchRoute<{}>>
    const pathOnlyUrl = url.split('?')[0]

    try {
      // Ask route directly if we can
      if (config.matchRoute?.(pathOnlyUrl, query)) {
        return [config, (config as any).matchRoute(pathOnlyUrl, query)]
      }

      // Otherwise check indirectly
      if (
        config.toPath?.(query) !== pathOnlyUrl &&
        config.href?.(query) !== pathOnlyUrl
      ) {
        continue
      }

      return [config, query]
    } catch (_e) {
      continue
    }
  }

  // No match found
  return undefined
}

/** Matches route and provides (route) path with ID parameters replaced with segment name
 *
 * i.e. `/venues/627cc609-8d60-47f3-91ec-db1d303b42fe/menu/item/4ca9aa53-e1a7-4226-ae48-cab5ad45e844`
 * becomes `/venues/(venue-id)/menu/item/(id)`
 *
 */
export const normalisedRouteForUrl = (
  url: string,
  query: ParsedUrlQuery,
): string | undefined => {
  if (!url || url === '') {
    return undefined
  }

  const routeAndParams = matchRoute(url, query)
  const [route, params] = routeAndParams || []

  if (!route) {
    return undefined
  }

  // filter /id$/ here
  const normalisedParams = mapValues(
    (params || {}) as Record<string, unknown>,
    (value, key) => {
      const normalisedValue = IdReg.test(key) ? `(${kebabCase(key)})` : value

      return normalisedValue
    },
  )

  // Can't guarentee we pass the right params to the right route, and since this
  // isn't critical we can just bail and live to norm another day
  try {
    return route.toPath?.(normalisedParams) || route.href(normalisedParams)
  } catch (_e) {
    return undefined
  }
}

export const queryValueIsString = (
  value: ParsedUrlQuery[string] | null,
): value is string => {
  return typeof value === 'string'
}

/**
 * This is an approximation of the `asPath` function from `next/router` which
 * returns the current path and query string.
 *
 * We use this version in circumstances where we have urql interactions that
 * results in unintentional re-renders.
 *
 * For more information see this comment from Joel [1].
 *
 * 1: https://github.com/mr-yum/manage-frontend/pull/294/files#r1377080511
 */
export const asPath = () => {
  return window.location.pathname + window.location.search
}

export const getReturnToQueryParams = () => {
  const path = asPath()

  if (!path || path === '/' || path === '/logout' || path.includes('/login')) {
    return ''
  }

  return `?returnTo=${path}`
}
