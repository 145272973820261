import { assertUnreachable } from 'lib/assertUnreachable'
import { ValidRole } from 'lib/auth'
import { StaffRole } from 'lib/gql'

export const mapStaffRoleToValidRole = (role: StaffRole): ValidRole => {
  switch (role) {
    case StaffRole.Admin:
      return 'admin'
    case StaffRole.DeliveryStaff:
      return 'delivery_staff'
    case StaffRole.MenuBuilder:
      return 'menu_builder'
    case StaffRole.Owner:
      return 'owner'
    case StaffRole.ServiceStaff:
      return 'service_staff'
    case StaffRole.VenueStaff:
      return 'venue_staff'
    default:
      assertUnreachable(role)
  }
}
