import 'whatwg-fetch'

import {
  FlagContext,
  FlagProvider,
  InMemoryStorageProvider,
  UnleashClient,
} from '@unleash/proxy-client-react'
import { useAuth } from 'hooks/useAuth'
import { useRegion } from 'hooks/useRegion'
import { ValidRole } from 'lib/auth'
import { releaseStage } from 'lib/config'
import { Region } from 'lib/region'
import React, { ReactNode, useContext, useEffect, useMemo } from 'react'
import { isServer } from 'utils/env'

const ProxyConfig = {
  url: 'https://app.unleash-hosted.com/euaa002/api/frontend',
  clientKey: 'd14e2618cad473a8f23e328',
  appName: 'default',
  environment: releaseStage,
}

type UnleashUserProperties = Partial<
  Record<
    'organizationId' | 'venueId' | 'region' | 'userRole' | 'userId',
    string
  >
>

const getUnleashContext = (
  region: Region,
  userRole: ValidRole,
  userId: string,
) => {
  const properties: UnleashUserProperties = { region: region.toString() }
  if (userRole) {
    properties.userRole = userRole
  }

  if (userId) {
    properties.userId = userId
  }

  return {
    properties,
  }
}

export const UnleashUserUpdater = ({
  organizationId,
  venueId,
  children,
}: {
  organizationId?: string
  venueId?: string
  children: ReactNode
}) => {
  const { updateContext } = useContext(FlagContext)
  const { region } = useRegion()
  const { state } = useAuth()
  const context = useMemo(() => {
    const context = getUnleashContext(region, state.user.role, state.user.id)

    if (organizationId) {
      context.properties.organizationId = organizationId
    }
    if (venueId) {
      context.properties.venueId = venueId
    }
    return context
  }, [region, state.user.role, state.user.id, organizationId, venueId])

  useEffect(() => {
    void updateContext(context)
  }, [updateContext, context])

  return <>{children}</>
}

export const UnleashProvider = ({ children }: { children: ReactNode }) => {
  const { region } = useRegion()
  const { state } = useAuth()

  const context = useMemo(() => {
    return getUnleashContext(region, state.user.role, state.user.id)
  }, [region, state.user.role, state.user.id])

  const client = useMemo(() => {
    const ssrOption = isServer
      ? {
          fetch: fetch,
          storageProvider: new InMemoryStorageProvider(),
          // bootstrap: [], // Are you looking to set some flags just for SSR?? Set them here!
        }
      : {}

    const unleashClient = new UnleashClient({
      ...ProxyConfig,
      ...ssrOption,
      context,
    })

    return unleashClient
  }, [context])

  return (
    <FlagProvider unleashClient={client} startClient={!isServer}>
      {children}
    </FlagProvider>
  )
}
