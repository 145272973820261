import { useAuth0, User } from '@auth0/auth0-react'
import { NotFound } from 'components/Common/NotFound'
import { SplashScreen } from 'components/SplashScreen/SplashScreen'
import { useRegion } from 'hooks/useRegion'
import jwtDecode from 'jwt-decode'
import { hasRole, SessionUser, ValidRole, ValidRoles } from 'lib/auth'
import { reportError } from 'lib/bugsnag'
import { AuthContext, AuthContextController } from 'lib/common-auth/context'
import { config } from 'lib/config'
import { StaffRole } from 'lib/gql'
import { asPath } from 'lib/routes/utils'
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { mapStaffRoleToValidRole } from 'utils/mapStaffRoleToValidRole'

export const getRoleFromUser = (user?: User | null) => {
  if (!user) {
    return null
  }
  const roles = user[`${config.auth0Audience}/roles`] ?? []
  const role =
    Array.isArray(roles) && roles.length > 0 ? roles[0].toLowerCase() : null

  return role
}

export const getIdFromUser = (user?: User | null) => {
  if (!user) {
    return null
  }
  return user[`${config.auth0Audience}/id`]
}

export const hasUser = (user?: User, error?: Error): boolean => {
  return (
    isValidId(getIdFromUser(user)) &&
    isValidRole(getRoleFromUser(user)) &&
    !error
  )
}

export const getTokenExpiry = (token: string | null): Date => {
  const expires = new Date(0)
  if (token) {
    const decoded = jwtDecode<any>(token)
    if (decoded.exp) {
      expires.setUTCSeconds(decoded.exp + AUTH0_TOKEN_SKEW_IN_SECONDS)
    }
  }
  return expires
}

export const isValidId = (id: any): id is string => typeof id === 'string'

export const isValidRole = (role: any): role is ValidRole =>
  ValidRoles.includes(role)

const getUser = (id: string, role: ValidRole): SessionUser => {
  return {
    role,
    id,
  }
}

export const AUTH0_TOKEN_SKEW_IN_SECONDS = 120

export const AuthController = ({ children }: { children: ReactNode }) => {
  const {
    isLoading,
    error,
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0()
  const { region } = useRegion()
  const role = getRoleFromUser(user)
  const id = getIdFromUser(user)
  const userExists =
    isAuthenticated &&
    hasUser(user, error) &&
    isValidRole(role) &&
    isValidId(id)
  const sessionUser = userExists ? getUser(id, role) : null

  const state = useMemo<AuthContext['state'] | null>(() => {
    return sessionUser
      ? {
          region,
          user: sessionUser,
        }
      : null
  }, [sessionUser, region])

  const login = useCallback(() => {
    void loginWithRedirect({
      appState: {
        returnTo: asPath(),
      },
    })
  }, [loginWithRedirect])

  /*
    Be very careful with the dep arrays in these functions as if they change by reference 
    then the whole urqlClient will be regenerated
  */
  const logoutWithRedirectURI = useCallback(() => {
    void logout({
      logoutParams: {
        // could be current URL later: https://linear.app/mr-yum/issue/SRV-2644/when-logging-out-return-the-user-to-where-they-were-originally-after
        returnTo:
          typeof window !== 'undefined'
            ? window.location.origin
            : config.auth0RedirectUri,
      },
    })
  }, [logout])

  // only to be used on the legacy login.page.tsx just to redirect to regular auth0
  const initLogin = useCallback(() => {
    void loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    })
  }, [loginWithRedirect])

  const swapAccount = useCallback(() => {
    // todo pass returnTo here: https://linear.app/mr-yum/issue/SRV-2572/handle-returnto-when-logging-outswapping-accounts
    logoutWithRedirectURI()
  }, [logoutWithRedirectURI])

  const refreshAuth = useCallback(async (): Promise<string | null> => {
    try {
      const token = await getAccessTokenSilently()
      if (token) {
        return token
      }
      // probably redundant as getAccessTokenSilently would likely do this
      reportError(new Error('Could not get access token'))
      login()
      return null
    } catch (err) {
      reportError(err)
      login()
      return null
    }
  }, [getAccessTokenSilently, login])

  // todo add returnUrl: https://linear.app/mr-yum/issue/SRV-2572/handle-returnto-when-logging-outswapping-accounts
  const initLogout = useCallback(() => {
    return logoutWithRedirectURI()
  }, [logoutWithRedirectURI])

  const userHasRole = useCallback(
    (roles: ValidRole[] | undefined) => {
      return hasRole(sessionUser ?? null, roles)
    },
    [sessionUser],
  )

  const isAdminOrMenuBuilder = useCallback(
    () => userHasRole(['menu_builder']),
    [userHasRole],
  )

  // `userHasRole` always returns true for admins
  const isAdmin = useCallback(() => userHasRole([]), [userHasRole])

  const userHasStaffRole = useCallback(
    (staffRoles: readonly StaffRole[]) => {
      const validRoles = staffRoles?.map(mapStaffRoleToValidRole)
      return hasRole(sessionUser ?? null, validRoles)
    },
    [sessionUser],
  )

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      login()
    }
  }, [isAuthenticated, login, isLoading])

  if (isLoading || !isAuthenticated) {
    return <SplashScreen />
  }

  if (!state || !sessionUser) {
    return (
      <NotFound
        title="Something went wrong."
        message="We're sorry, please try again."
      />
    )
  }

  const contextValue: AuthContext = {
    logout: logoutWithRedirectURI,
    initLogout,
    initLogin,
    login,
    user: sessionUser,
    userHasRole,
    isAdminOrMenuBuilder,
    isAdmin,
    getToken: getAccessTokenSilently,
    swapAccount,
    state,
    refreshAuth,
    userHasStaffRole,
  }

  return (
    <AuthContextController.Provider value={contextValue}>
      {children}
    </AuthContextController.Provider>
  )
}
