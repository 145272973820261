import { useScript } from '@mr-yum/frontend-core/dist/hooks/useScript'
import { useAuth } from 'hooks/useAuth'
import { config } from 'lib/config'
import { normalisedRouteForUrl } from 'lib/routes/utils'
import Router, { NextRouter, useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect, useMemo } from 'react'
import { isServer } from 'utils/next'

export const gtag = (() => {
  const noop = (() => {
    /* */
  }) as Gtag.Gtag

  const hasWindow = !isServer && typeof window !== 'undefined'
  if (!hasWindow) {
    return noop
  }

  // Even though `window.gtag` doesn't change at run time, this is the only
  // sane way we can have this work and test it also
  return ((...args: any[]) => {
    const fn = (window as any).gtag || noop
    fn.apply(fn, args as any)
  }) as Gtag.Gtag
})()

export const GoogleTagManager: React.FC = () => {
  const googleTagId = config.googleTagId

  // Setup
  useEffect(() => {
    gtag('js', new Date())
    gtag('config', googleTagId)
    // Turn off automatic page view events as we handle them ourselves
    // We also need to configure the GTM tag not to trigger on history API
    gtag('set', 'send_page_view', false)
  }, [googleTagId])

  // User values
  const { user } = useAuth()
  useEffect(() => {
    const role = user.role ?? ''
    const id = user.id ?? ''

    gtag('set', 'user_properties', { role })
    gtag('set', 'user_id', id)
  }, [user])

  // Page view
  const router = useRouter()
  const onRouteChange = useMemo(() => {
    return (url: string) => {
      // @cleanup: We use the singleton to access this value as we have to get
      // the current query, whereas `useRouter().query` is for the route we
      // just left. The fallback is only here for testing purposes since mocking
      // module defaults isn't a thing
      const { query } = (Router.router! as NextRouter) || router
      const path = normalisedRouteForUrl(url, query)

      gtag('event', 'page_view', {
        page_route: path,
      })
    }
  }, [router])

  // Initial call (should fire exactly once)
  useEffect(() => {
    if (router.isReady) {
      onRouteChange(router.asPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Every page change
  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChange)

    return () => {
      router.events.off('routeChangeComplete', onRouteChange)
    }
  }, [router, onRouteChange])

  // We want, ideally, to set the above params before the GTM lib is loaded
  useScript(`//www.googletagmanager.com/gtag/js?id=${googleTagId}`)

  return <GTMScript />
}

// GTM is rather particular about how these two values are defined,
// so it's easiest if we just leave them here
const GTMScript: React.FC = () => {
  return (
    <Script
      id="gtag"
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
          `,
      }}
    />
  )
}
