export const isServer = typeof window === 'undefined'

/** Checks if a NextPage has a specified layout */
export function hasLayout(page: any): any {
  return page?.getLayout !== undefined
}

export function hasGetInitialProps(page: any): any {
  return page.getInitialProps !== undefined
}
