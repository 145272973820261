import { Logo } from '@mr-yum/frontend-ui'
import React from 'react'

export const SplashScreen = () => (
  <div
    data-testid="splash-screen"
    className="flex h-screen items-center justify-center"
  >
    <Logo className="h-14 w-auto" />
  </div>
)
