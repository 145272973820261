import { RegionProvider } from 'hooks/useRegion'
import { AppInitialProps, AppProps } from 'next/app'
import { createElement } from 'react'
import { hasGetInitialProps, hasLayout } from 'utils/next'

import { getRegionFromStore, Region } from './region'

interface Props {
  region: Region
}

/** Wraps an App and provides it with a region
 *
 * The region is inserted into both the props and context
 */
// @enhance: it's relatively simple to extend this function to handle NextPages except
// for the whole mess that is the Next.js type definitions. The main practical
// difference is where 'context' lives that we care about, i.e.:
// `AppContext.ctx === PageContext`. That and what nesting of props it wants.
// You can check if it's an app by looking at either `.ctx` or `.Component`
// Given that we only need an app wrapper for now, that's all we do here
export function withRegion(app: any) {
  const WithRegionWrapper = (props: AppProps & Props) => {
    const innerProps: any = { ...props }

    // We need to use the region from props because when we're running server
    // side the region is fetched from the page context request, which is only
    // available in `getInitialProps`
    // @cleanup: this could be tsx but some Type Jazz is happening that is not
    // worth looking into just now
    return createElement(
      RegionProvider,
      { region: props.region },
      createElement(app, innerProps),
    )
  }

  // Set the displayName to indicate use of withRegion.
  const displayName = app.displayName || app.name || 'Component'
  WithRegionWrapper.displayName = `withRegion(${displayName})`

  if (hasLayout(app)) {
    WithRegionWrapper.getLayout = app.getLayout
  }

  if (hasGetInitialProps(app)) {
    WithRegionWrapper.getInitialProps = async (
      initialContext: any,
    ): Promise<AppInitialProps & Props> => {
      const region = getRegionFromStore(initialContext.ctx)

      const context = initialContext

      context.ctx.region = region

      const pageProps = await app.getInitialProps(context)

      return { ...pageProps, region }
    }
  }

  return WithRegionWrapper
}
