import { Region } from './region'

export type ValidRole =
  | 'admin'
  | 'menu_builder'
  | 'owner'
  | 'venue_staff'
  | 'service_staff'
  | 'delivery_staff'
  | 'user'

export const ValidRoles: ValidRole[] = [
  'admin',
  'menu_builder',
  'owner',
  'venue_staff',
  'service_staff',
  'delivery_staff',
  'user',
]

export interface SessionUser {
  id: string
  role: ValidRole
}

/** All values valid if present */
export interface State {
  user: SessionUser
  /** User is valid in this region only */
  region: Region
}

/** User has role or is admin role */
export function hasRole(
  user: SessionUser | null,
  roles: ValidRole[] | undefined = [],
): boolean {
  if (!user || !roles) {
    return false
  }

  return roles.includes(user.role) || user.role === 'admin'
}
