/*
    Copied from urql/core: https://github.com/urql-graphql/urql/blob/main/packages/core/src/utils/variables.ts
    Provides a way to find out if graphql variables in a payload has any file uploads inside.
*/

export type FileMap = Map<string, File | Blob>

const seen = new Set()

const extract = (map: FileMap, path: string, x: any) => {
  if (x == null || typeof x !== 'object' || x.toJSON || seen.has(x)) {
    /*noop*/
  } else if (Array.isArray(x)) {
    for (let i = 0, l = x.length; i < l; i++) extract(map, `${path}.${i}`, x[i])
  } else if (x instanceof FileConstructor || x instanceof BlobConstructor) {
    map.set(path, x as File | Blob)
  } else {
    seen.add(x)
    for (const key of Object.keys(x)) extract(map, `${path}.${key}`, x[key])
  }
}

class NoopConstructor {}
const FileConstructor = typeof File !== 'undefined' ? File : NoopConstructor
const BlobConstructor = typeof Blob !== 'undefined' ? Blob : NoopConstructor

export const extractFiles = (x: any): FileMap => {
  const map: FileMap = new Map()
  if (
    FileConstructor !== NoopConstructor ||
    BlobConstructor !== NoopConstructor
  ) {
    seen.clear()
    extract(map, 'variables', x)
  }
  return map
}
