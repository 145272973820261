import { config } from 'lib/config'

export function apiUrlForDomain(host: string): string {
  if (host.includes('meandu.app')) {
    return config.apiUrlMeandu
  }
  return config.apiUrl
}

export const useApiUrl = () => {
  return apiUrlForDomain(window.location.host.toString())
}
