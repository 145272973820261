import { useChatSupport } from 'hooks/useChatSupport'
import React from 'react'

const ChatPlaceholder: React.FC<{ type: 'zendesk' | 'none' }> = ({ type }) => {
  return <div id="chat-container" data-type={type} data-testid="chat" hidden />
}

const ChatSupportWidget = () => {
  const chatSupportReady = useChatSupport()
  return <>{chatSupportReady && <ChatPlaceholder type={'zendesk'} />}</>
}

export const ChatSupport = () => {
  return <ChatSupportWidget />
}
