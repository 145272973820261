import { Currency } from '@mr-yum/yum-ui'
import { config } from 'lib/config'
import { CountryCode } from 'libphonenumber-js/max'
import { NextPageContext } from 'next'

import { CookieAccess, cookiesForContext } from './cookies'

export type Region = 'ap1' | 'eu1' | 'us1'
/** Used for when even the deploy-time config region is absent */
const FALLBACK_REGION: Region = 'ap1'
/** Used when a specific region has not been select */
export const DEFAULT_REGION: Region =
  (config.region as Region) ?? FALLBACK_REGION

const REGION_COOKIE_NAME = 'mryum.region'
const REGION_COOKIE_EXPIRY_DAYS = 365
const MILLISECONDS_IN_DAY = 864e5

const defaultCountryCodeByRegion: Record<Region, CountryCode> = {
  ap1: 'AU',
  eu1: 'GB',
  us1: 'US',
}

export const defaultCountryCodeForRegion = (region?: Region): CountryCode => {
  return (
    defaultCountryCodeByRegion[region || DEFAULT_REGION] ||
    defaultCountryCodeByRegion[FALLBACK_REGION]
  )
}
const defaultCurrencyByRegion: Record<Region, Currency> = {
  ap1: 'AUD',
  eu1: 'GBP',
  us1: 'USD',
}

export const defaultCurrencyForRegion = (region?: Region): Currency => {
  return (
    defaultCurrencyByRegion[region || DEFAULT_REGION] ||
    defaultCurrencyByRegion[FALLBACK_REGION]
  )
}

/** Gets region from cookie (and sets to default if not present) */
export const getRegionFromStore = (
  ctx?: Pick<NextPageContext, 'req' | 'res'>,
): Region => {
  const cookies = cookiesForContext(ctx)
  const region = cookies.get(REGION_COOKIE_NAME) as Region

  if (region) {
    return region
  }

  setRegion(DEFAULT_REGION, cookies)
  return DEFAULT_REGION
}

/** Sets region cookie on client only */
export const setRegion = (region: Region, cookies?: CookieAccess) => {
  const expires = new Date(
    Date.now() + REGION_COOKIE_EXPIRY_DAYS * MILLISECONDS_IN_DAY,
  )
  ;(cookies ?? cookiesForContext()).set(REGION_COOKIE_NAME, region, {
    expires,
    path: '/',
  })
}
